<template>
  <div class="authList">
    <div class="derive">
      <el-button size="small" @click="createAuth()">新增权限</el-button>
    </div>
    <div class="auth-table">
      <el-table :data="tableData" header-cell-class-name="table_header" row-key="id" border :tree-props="{children: 'child', hasChildren: 'hasChildren'}">
        <el-table-column prop="auth_title" label="名称"/>
        <el-table-column prop="auth_key" label="KEY"/>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
            <el-button type="text" size="small" @click="createChildAuth(scope.row)">新增子权限</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <create-auth ref="createAuth" @ok="allAuthList"/>
    <createChildAuth ref="createChildAuth"/>
  </div>
</template>

<script>
import {allAuthList,authOperate} from "@/api/auth.js"
import createAuth from './components/createAuth.vue';
import createChildAuth from './components/createChildAuth.vue'
export default {
  components: { createAuth,createChildAuth },
  name: 'authList',

  data() {
    return {
      tableData:[]
    };
  },

  mounted() {
    this.allAuthList()
  },

  methods: {
    async allAuthList(){
      const {code,data} = await allAuthList()
      if(code == 200){
        this.tableData = data
      }
    },
    edit(row){
      if(row.auth_pid == 0){
        this.$refs.createAuth.isShow(row,"edit")
      } else {
        this.$refs.createChildAuth.isShow(row,"edit")
      }
    },
    del(row){
      this.$confirm('是否删除当前权限？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const param = {
          authId : row.id,
          isMenu : row.is_menu,
          authType : row.auth_type,
          authTitle : row.auth_title,
          authKey : row.auth_key,
          ...row,
          authStatus:2,
        }
        const {code} = await authOperate(param)
        if(code == 200){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.allAuthList()
        }
        
      }).catch(() => {
      })
    },
    createChildAuth(row){
      this.$refs.createChildAuth.isShow(row)
    },
    createAuth(){
      this.$refs.createAuth.isShow()
    },
  }
};
</script>

<style lang="scss" scoped>
.authList{
  .derive{
    margin-bottom: 10px;
  }  
  .auth-table{
    /deep/ .table_header{
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>