import request from '@/utils/request'


export function allAuthList (params) {
  return request.post('/api/allAuthList', params)
}

export function authOperate (params) {
  return request.post('/api/authOperate', params)
}

export function getRouteList (params) {
  return request.post('/api/routeList', params)
}